// import Vue from "vue";



const routes = [{
  path: '/g/:id',
  name: 'mainPage',
  component: () => import('@/views/Index')
},
{
  path: '/',
  name: 'mainPage',
  component: () => import('@/views/Index')
},
{
  path: '/login',
  name: 'login',
  component: () => import('@/views/Login')
},
{
  path: '/r/:invitationCode',
  name: 'register',
  component: () => import('@/views/Register')
},
{
  path: '/register',
  name: 'register',
  component: () => import('@/views/Register')
},
{
  path: '/forget',
  name: 'forget',
  component: () => import('@/views/Forget')
}
]


export default routes;

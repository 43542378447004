import Vue from 'vue'
import VueRouter from "vue-router";
Vue.use(VueRouter)
// import store from '@/store/index';
import { Message } from 'element-ui';
import { getToken } from "@/utils/auth.js";
// 路由数据
import routes from './router.js';
const whitelist = ["/register", "/forget"]
// 导出路由 在 main.js 里使用
const router = new VueRouter({
  mode: "history",
  routes,
});
/**
 * 路由拦截
 * 权限验证
 */
router.beforeEach((to, from, next) => {
  const token = getToken();
  if (token) {
    next();
  } else {
    if (to.path === '/login') {
      next();
    } else {
      if (whitelist.includes(to.path) || to.path.includes("/register/")) {
        next();
      } else {
        Message.info("请先登录哦！！！")
        next({
          path: '/login'
        });
      }
    }
  }
});


export default router;
